import Siema from 'siema';
	$crisp.push(["safe", true]);

if(	 document.querySelectorAll(".carousel").length > 0) {


const carousel = new Siema({
    selector: '.carousel',
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    multipleDrag: true,
    threshold: 20,
    loop: true,
    rtl: false,
});
setInterval(function() {
    carousel.next();
}, 5000);
}

	function jsonp(url, callback) {
		var callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
		window[callbackName] = function(data) {
			delete window[callbackName];
			document.body.removeChild(script);
			callback(data);
		};

		var script = document.createElement('script');
		script.src = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'callback=' + callbackName;
		document.body.appendChild(script);
	}


	window.CRISP_READY_TRIGGER = function() {
		var v = $crisp.get("user:email");


		  if (v != null) {
			var ele = document.querySelectorAll("input[name=name]");
			ele.forEach(function(input) {
				var v = $crisp.get("user:nickname");
				input.value = v;
			});

			ele = document.querySelectorAll("input[name=email]");
			ele.forEach(function(input) {
				var v = $crisp.get("user:email");
				input.value = v;
			});

			ele = document.querySelectorAll("input[name=cellPhone]");
			ele.forEach(function(input) {
				var v = $crisp.get("user:phone");
				input.value = v;
			});
		}

		function emailchanged() {


			gtag('event', 'conversion', {
				'send_to': 'AW-968776288/q-maCJ6w0qUBEOC0-c0D'
			});
			return false;
		};

		$crisp.push(["on", "user:email:changed", emailchanged]);
	};

var el;


	el = document.querySelectorAll("input[name=name]");
	el.forEach(function(input) {
		input.addEventListener('blur', function() {
			if (input.value.trim())
			{
				$crisp.push(["set", "user:nickname", [input.value]]);

			}
		});
	});

	el = document.querySelectorAll("input[name=email]");
	el.forEach(function(input) {
		input.addEventListener('blur', function() {
			if ((input.value.trim()) && (input.validity.valid))
			{
				$crisp.push(["set", "user:email", [input.value]]);

			}
		});
	});


	el = document.querySelectorAll("input[name=cellPhone]");
	var sub = document.querySelectorAll("button[type=submit]");

	sub.forEach(s => s.disabled = true );
	el.forEach(function(input) {
		input.type = "tel";
		var iti = window.intlTelInput(input,  {
			initialCountry: "auto",
			utilsScript: "/build/js/utils-min.js",
			nationalMode: false,
			autoHideDialCode: false,
			autoPlaceholder: "aggressive",
			geoIpLookup: function(success, failure) {
				jsonp("https://ipinfo.io?token=3fc16c785f6db8", function(resp) {
					var countryCode = (resp && resp.country) ? resp.country : "";
					success(countryCode);
				});
			}, });



		var reset = function() {
			input.classList.remove("error");
			input.classList.remove("valid");
			sub.forEach(s => s.disabled = true );

		};

		input.addEventListener('blur', function() {
			reset();
			if (input.value.trim()) { 
				if (iti.isValidNumber()) {
					input.classList.add("valid");
					var p = iti.getNumber();
					$crisp.push(["set", "user:phone", [p]]);
					sub.forEach(s => s.disabled = false );


				} else {
					input.classList.add("error");
					sub.forEach(s => s.disabled = true );

				}
			}
		});

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);

    input.autocomplete = "tel";



    new LuminousGallery(document.querySelectorAll(".lightbox"), {
    	arrowNavigation: true
    });

    function shownav() {
    	el = document.querySelector(".dropdown-holder");
    	el.style.display = "block";
    	els = document.querySelectorAll(".dropdown-menu");
    	els.forEach( e => e.style.opacity = 1 );

    	body = document.querySelector("body");
    	body.style.overflow = "hidden";
    	return false;
    }

    function hidenav() {
    	el = document.querySelector(".dropdown-holder");
    	el.style.display = "none";
    	els = document.querySelectorAll(".dropdown-menu");
    	els.forEach( e => e.style.opacity = 1 );

    	body = document.querySelector("body");
    	body.style.overflow = "auto";
    	return false;

    }

    if (document.querySelector(".financering") !==  null ) {
    	var mixer = mixitup('.financering', {
    		selectors: {
    			target: '.filterable'
    		},
    		animation: {
    			enable: false,
    			effects: 'fade'
    		},
    		load: {
    			filter: '.dur_20_years'
    		},
    		controls: {
    			toggleDefault: '.dur_20_years'
    		}
    	});
    }


    if ( window.screen.width > 700 ) {
    	var menuItems         = [].slice.call( document.querySelectorAll( '.menuitem' ) ),
    	menuSubs          = [].slice.call( document.querySelectorAll( '.dropdown-menu') ),
    	selectedMenu      = undefined,
    	subBg			  = document.querySelector( '.dropdown__bg' ),
    	subBgBtm		  = document.querySelector( '.dropdown__bg-bottom' ),
    	subArr			  = document.querySelector( '.dropdown__arrow' ),
    	subCnt            = document.querySelector( '.dropdown__wrap' ),
    	header			  = document.querySelector( 'nav.wrap' ),
    	closeDropdownTimeout,



    	startCloseTimeout = function (){
    		closeDropdownTimeout = setTimeout( () => closeDropdown() , 50 );
    	},
    	
    	stopCloseTimeout   = function () {
    		clearTimeout( closeDropdownTimeout );
    	},
    	
    	openDropdown      = function (el) {

    		var m = document.querySelector("nav.wrap").getBoundingClientRect();

		//- get menu ID
		var menuId     = el.getAttribute( 'data-sub' );
		//- get related sub menu
		var menuSub    = document.querySelector( '.dropdown-menu[data-sub="'+menuId+'"]' );
		//- get menu sub content
		var menuSubCnt = menuSub.querySelector( '.dropdown-menu__content' )
		//- get bottom section of current sub
		var menuSubBtm = menuSubCnt.querySelector('.bottom-section').getBoundingClientRect();
		//- get height of top section
		var menuSubTop = menuSubCnt.querySelector('.top-section').getBoundingClientRect();
		//- get menu position
		var menuMeta   = el.getBoundingClientRect();
		//- get sub menu position
		var subMeta    = menuSubCnt.getBoundingClientRect();
		//- set selected menu
		selectedMenu = menuId;

		//- Remove active Menu
		menuItems.forEach( el => el.classList.remove( 'active' ) );
		//- Set current menu to active
		el.classList.add( 'active' );
		
		//- Remove active sub menu
		menuSubs.forEach( el => el.classList.remove( 'active' ) );
		//- Set current menu to active
		menuSub.classList.add( 'active' );

		//- Set dropdown menu background style to match current submenu style
		subBg.style.opacity    = 1;
		subBg.style.left       = menuMeta.left - ( (subMeta.width / 2) - menuMeta.width / 2 ) - m.left + 'px';
		subBg.style.width      = subMeta.width+'px';
		subBg.style.height     = subMeta.height+'px';
		//- Set dropdown menu bottom section background position
		subBgBtm.style.top     = menuSubTop.height+'px';
		
		//- Set Arrow position
		subArr.style.opacity  = 1;
		subArr.style.left     = menuMeta.left + menuMeta.width/2 - 10 - m.left + 'px';
		
		//- Set sub menu style
		subCnt.style.opacity = 1;
		subCnt.style.left    = menuMeta.left - ( (subMeta.width / 2) - menuMeta.width / 2 ) - m.left + 'px';
		subCnt.style.width   = subMeta.width+'px';
		subCnt.style.height  = subMeta.height+'px';
		
		//- Set current sub menu style
		menuSub.style.opacity = 1;
		
		header.classList.add('dropdown-active');

	},
	closeDropdown     = function () {

		//- Remove active class from all menu items
		menuItems.forEach( el => el.classList.remove('active') );
		//- Remove active class from all sub menus
		menuSubs.forEach ( el => {
			el.classList.remove( 'active' );
			el.style.opacity = 0;
		} );
		//- set sub menu background opacity
		subBg.style.opacity   = 0;
		//- set arrow opacity
		subArr.style.opacity = 0;
		

		// unset selected menu
		selectedMenu = undefined;
		
		header.classList.remove('dropdown-active');
	};


//- Binding mouse event to each menu items
menuItems.forEach( el => {
	
	//- mouse enter event
	el.addEventListener( 'mouseenter', function() {
		stopCloseTimeout();
		openDropdown( this );
	}, false );
	
	//- mouse leave event
	el.addEventListener( 'mouseleave', () => startCloseTimeout(), false);

} );

//- Binding mouse event to each sub menus
menuSubs.forEach( el => {

	el.addEventListener( 'mouseenter', () => stopCloseTimeout(), false );
	el.addEventListener( 'mouseleave', () => startCloseTimeout(), false );

} );

}

function showpictures() {
	var gridzyinstance = document.querySelector('.gridzy').gridzy;
	gridzyinstance.setOptions({
		filter: '*'
	});
	document.querySelector(".loadmorepic").style.display = "none";
}

function readmore() {
	document.querySelector(".continuation").style.display = "block";
	document.querySelector(".readmoredesc").style.display = "none";
}


if (document.querySelector("#map-holder") !== null) {

	var map = L.map('map-holder', {scrollWheelZoom: false,  hoverToWake: false, sleepNote: false}).setView([14.4027, 120.9029], 10);
	L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
		attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
		maxZoom: 18,
		id: 'mapbox.streets',
		accessToken: 'pk.eyJ1IjoicHJvZHVjdHVyZSIsImEiOiJfLWVObWQwIn0.HiG8QauGgzqT6hNglDBzzw'
	}).addTo(map);

	var marker = L.marker([14.4027, 120.9029]).addTo(map);
}

});


document.addEventListener("DOMContentLoaded", function(xxx) {

});

$crisp.push(["safe", true]);

function jsonp(url, callback) {
    var callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
    window[callbackName] = function(data) {
        delete window[callbackName];
        document.body.removeChild(script);
        callback(data);
    };

    var script = document.createElement('script');
    script.src = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'callback=' + callbackName;
    document.body.appendChild(script);
}

window.CRISP_READY_TRIGGER = function() {
    var v = $crisp.get("user:email");

    if (v != null) {
    el = document.querySelectorAll("input[name=name]");
    el.forEach(function(input) {
    var v = $crisp.get("user:nickname");
        input.value = v;
    });

    el = document.querySelectorAll("input[name=email]");
    el.forEach(function(input) {
        var v = $crisp.get("user:email");
        input.value = v;
    });

    el = document.querySelectorAll("input[name=cellPhone]");
    el.forEach(function(input) {
        var v = $crisp.get("user:phone");
        input.value = v;
    });
    }

    function emailchanged() {


        gtag('event', 'conversion', {
        'send_to': 'AW-968776288/q-maCJ6w0qUBEOC0-c0D'
        });
        return false;
    };

    $crisp.push(["on", "user:email:changed", emailchanged]);
};


el = document.querySelectorAll("input[name=name]");
el.forEach(function(input) {
       input.addEventListener('blur', function() {
        if (input.value.trim())
        {
            $crisp.push(["set", "user:nickname", [input.value]]);

        }
    });
});

el = document.querySelectorAll("input[name=email]");
el.forEach(function(input) {
    input.addEventListener('blur', function() {
        if ((input.value.trim()) && (input.validity.valid))
        {
            $crisp.push(["set", "user:email", [input.value]]);

        }
    });
});



el = document.querySelectorAll("input[name=cellPhone]");
var sub = document.querySelectorAll("button[type=submit]");

sub.forEach(s => s.disabled = true );
el.forEach(function(input) {
    input.type = "tel";
          var iti = window.intlTelInput(input,  {
               initialCountry: "auto",
              utilsScript: "/build/js/utils-min.js",
              nationalMode: false,
               autoHideDialCode: false,
    autoPlaceholder: "aggressive",
    geoIpLookup: function(success, failure) {
        jsonp("https://ipinfo.io?token=3fc16c785f6db8", function(resp) {
            var countryCode = (resp && resp.country) ? resp.country : "";
            success(countryCode);
        });
    }, });



    var reset = function() {
        input.classList.remove("error");
        input.classList.remove("valid");
        sub.forEach(s => s.disabled = true );

    };

    input.addEventListener('blur', function() {
        reset();
        if (input.value.trim()) { 
            if (iti.isValidNumber()) {
                input.classList.add("valid");
                var p = iti.getNumber();
                $crisp.push(["set", "user:phone", [p]]);
                sub.forEach(s => s.disabled = false );


            } else {
                input.classList.add("error");
                sub.forEach(s => s.disabled = true );

            }
        }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);

    input.autocomplete = "tel";

});

new LuminousGallery(document.querySelectorAll(".lightbox"), {
    arrowNavigation: true
});

function shownav() {
    el = document.querySelector(".dropdown-holder");
    el.style.display = "block";
    els = document.querySelectorAll(".dropdown-menu");
    els.forEach( e => e.style.opacity = 1 );

    body = document.querySelector("body");
    body.style.overflow = "hidden";
    return false;
}

function hidenav() {
    el = document.querySelector(".dropdown-holder");
    el.style.display = "none";
    els = document.querySelectorAll(".dropdown-menu");
    els.forEach( e => e.style.opacity = 1 );

    body = document.querySelector("body");
    body.style.overflow = "auto";
    return false;

}

if (document.querySelector(".financering") !==  null ) {
var mixer = mixitup('.financering', {
    selectors: {
        target: '.filterable'
    },
    animation: {
        enable: false,
        effects: 'fade'
    },
    load: {
        filter: '.dur_20_years'
    },
    controls: {
        toggleDefault: '.dur_20_years'
    }
});
}


if ( window.screen.width > 700 ) {
var menuItems         = [].slice.call( document.querySelectorAll( '.menuitem' ) ),
	menuSubs          = [].slice.call( document.querySelectorAll( '.dropdown-menu') ),
	selectedMenu      = undefined,
	subBg			  = document.querySelector( '.dropdown__bg' ),
	subBgBtm		  = document.querySelector( '.dropdown__bg-bottom' ),
	subArr			  = document.querySelector( '.dropdown__arrow' ),
	subCnt            = document.querySelector( '.dropdown__wrap' ),
	header			  = document.querySelector( 'nav.wrap' ),
	closeDropdownTimeout,



	startCloseTimeout = function (){
		closeDropdownTimeout = setTimeout( () => closeDropdown() , 50 );
	},
	
	stopCloseTimeout   = function () {
		clearTimeout( closeDropdownTimeout );
	},
	
	openDropdown      = function (el) {

	    var m = document.querySelector("nav.wrap").getBoundingClientRect();

		//- get menu ID
		var menuId     = el.getAttribute( 'data-sub' );
		//- get related sub menu
		var menuSub    = document.querySelector( '.dropdown-menu[data-sub="'+menuId+'"]' );
		//- get menu sub content
		var menuSubCnt = menuSub.querySelector( '.dropdown-menu__content' )
		//- get bottom section of current sub
		var menuSubBtm = menuSubCnt.querySelector('.bottom-section').getBoundingClientRect();
		//- get height of top section
		var menuSubTop = menuSubCnt.querySelector('.top-section').getBoundingClientRect();
		//- get menu position
		var menuMeta   = el.getBoundingClientRect();
		//- get sub menu position
		var subMeta    = menuSubCnt.getBoundingClientRect();
		//- set selected menu
		selectedMenu = menuId;

		//- Remove active Menu
		menuItems.forEach( el => el.classList.remove( 'active' ) );
		//- Set current menu to active
		el.classList.add( 'active' );
		
		//- Remove active sub menu
		menuSubs.forEach( el => el.classList.remove( 'active' ) );
		//- Set current menu to active
		menuSub.classList.add( 'active' );

		//- Set dropdown menu background style to match current submenu style
		subBg.style.opacity    = 1;
		subBg.style.left       = menuMeta.left - ( (subMeta.width / 2) - menuMeta.width / 2 ) - m.left + 'px';
		subBg.style.width      = subMeta.width+'px';
		subBg.style.height     = subMeta.height+'px';
		//- Set dropdown menu bottom section background position
		subBgBtm.style.top     = menuSubTop.height+'px';
		
		//- Set Arrow position
		subArr.style.opacity  = 1;
		subArr.style.left     = menuMeta.left + menuMeta.width/2 - 10 - m.left + 'px';
		
		//- Set sub menu style
		subCnt.style.opacity = 1;
		subCnt.style.left    = menuMeta.left - ( (subMeta.width / 2) - menuMeta.width / 2 ) - m.left + 'px';
		subCnt.style.width   = subMeta.width+'px';
		subCnt.style.height  = subMeta.height+'px';
		
		//- Set current sub menu style
		menuSub.style.opacity = 1;
		
		header.classList.add('dropdown-active');

	},
	closeDropdown     = function () {

		//- Remove active class from all menu items
		menuItems.forEach( el => el.classList.remove('active') );
		//- Remove active class from all sub menus
		menuSubs.forEach ( el => {
			el.classList.remove( 'active' );
			el.style.opacity = 0;
		} );
		//- set sub menu background opacity
		subBg.style.opacity   = 0;
		//- set arrow opacity
		subArr.style.opacity = 0;
		

		// unset selected menu
		selectedMenu = undefined;
		
		header.classList.remove('dropdown-active');
	};


//- Binding mouse event to each menu items
menuItems.forEach( el => {
	
	//- mouse enter event
	el.addEventListener( 'mouseenter', function() {
		stopCloseTimeout();
		openDropdown( this );
	}, false );
	
	//- mouse leave event
	el.addEventListener( 'mouseleave', () => startCloseTimeout(), false);

} );

//- Binding mouse event to each sub menus
menuSubs.forEach( el => {

	el.addEventListener( 'mouseenter', () => stopCloseTimeout(), false );
	el.addEventListener( 'mouseleave', () => startCloseTimeout(), false );

} );

}

    function showpictures() {
        var gridzyinstance = document.querySelector('.gridzy').gridzy;
        gridzyinstance.setOptions({
            filter: '*'
        });
        document.querySelector(".loadmorepic").style.display = "none";
    }

function readmore() {
    document.querySelector(".continuation").style.display = "block";
    document.querySelector(".readmoredesc").style.display = "none";
}


if (document.querySelector("#map-holder") !== null) {

var map = L.map('map-holder', {scrollWheelZoom: false,  hoverToWake: false, sleepNote: false}).setView([14.4027, 120.9029], 10);
L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: 'mapbox.streets',
    accessToken: 'pk.eyJ1IjoicHJvZHVjdHVyZSIsImEiOiJfLWVObWQwIn0.HiG8QauGgzqT6hNglDBzzw'
}).addTo(map);

var marker = L.marker([14.4027, 120.9029]).addTo(map);
}

